import Head from 'next/head';

import { BodyText } from '@virginexperiencedays/components-v2/src/typography/BodyText';
import { VisualHeading } from '@virginexperiencedays/components-v2/src/typography/VisualHeading';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import DynamicYield from '../components/DynamicYield/DynamicYield';

import { fetchVedNavigationItems } from '../utils/precache/navigation-items';
import { getTopProducts } from '../utils/getTopProducts';
import { DyContextPageType } from '@virginexperiencedays/dy';

const Page = () => {
  return (
    <>
      <Head>
        <title>The page you were looking for isn&apost here!</title>
      </Head>
      <DynamicYield type={DyContextPageType.OTHER} data="404" />
      <Container className="mx-auto my-10">
        <VisualHeading className="text-center">404</VisualHeading>
        <VisualHeading className="text-center" size="5">
          We could not find the page you were looking for!
        </VisualHeading>
        <BodyText className="my-4 text-center">
          Please use either the navigation above to browse our experiences, category blocks below,
          or the search to find the type of experience that you would like.
        </BodyText>
      </Container>
    </>
  );
};

const navigationServiceUrl = process?.env?.NEXT_PUBLIC_NAVIGATION_SERVICE_BASE_URL;

export const getStaticProps = async () => {
  const initialNavItems = await fetchVedNavigationItems(navigationServiceUrl);

  const topProducts = await getTopProducts();
  return {
    props: {
      topProducts,
      initialNavItems,
    },
  };
};
export default Page;
